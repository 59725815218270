<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl py-0 px-3>
            <v-layout row wrap>
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "PoBoard"
    }
</script>

<style scoped>

</style>